const getTranslatedPath = (path, lang) => {
  const pathSegments = path.split("/").filter(Boolean);

  if (lang === "ua") {
    pathSegments.shift();
    return `/${pathSegments.join("/")}`;
  } else {
    return `/${lang}/${pathSegments.join("/")}`;
  }
};

export default getTranslatedPath;
