import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../services/axiosConfig";

const setAuthHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = "";
};

export const login = createAsyncThunk(
  "auth/login",
  async (obj, { rejectWithValue }) => {
    try {
      const responce = await axios.post("/auth/login", { ...obj });
      setAuthHeader(responce.data.token);

      return responce.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    clearAuthHeader();
    try {
      const responce = await axios.post("/auth/logout");

      return responce.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const refresh = createAsyncThunk(
  "auth/refresh",
  async (_, { rejectWithValue, getState }) => {
    const state = getState();
    if (state.auth.token === null) {
      return rejectWithValue();
    }

    setAuthHeader(state.auth.token);
    await axios.get("/auth/current");
  }
);
