import { configureStore } from "@reduxjs/toolkit";
import { headerTypeReducer } from "./headerType/headerTypeSlice";
import { languageReducer } from "./language/languageSlise";
import { projectsReducer } from "./projects/projectSlice";
import { vacanciesReduser } from "./vacancies/vacanciesSlice";
import { analyticsReducer } from "./analytics/analyticsSlice";
import { newsReduser } from "./news/newsSlice";
import { authReducer } from "./auth/authSlice";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

export const store = configureStore({
  reducer: {
    headerType: headerTypeReducer,
    language: languageReducer,
    projects: projectsReducer,
    vacancies: vacanciesReduser,
    analytics: analyticsReducer,
    news: newsReduser,
    auth: authReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
