import { createSlice } from "@reduxjs/toolkit";
import {
  fetchVacancies,
  addVacancy,
  removeVacancy,
  updateVacancy,
  updateVisibility,
} from "./vacanciesOperations";

const vacanciesInitialState = {
  vacancies: [],
  pagesCount: null,
  isLoading: false,
  error: null,
};

const handlePending = (state) => {
  state.isLoading = true;
};
const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const vacanciesSlice = createSlice({
  name: "vacancies",
  initialState: vacanciesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancies.pending, handlePending)
      .addCase(addVacancy.pending, handlePending)
      .addCase(updateVacancy.pending, handlePending)
      .addCase(updateVisibility.pending, handlePending)
      .addCase(removeVacancy.pending, handlePending)
      .addCase(fetchVacancies.rejected, handleRejected)
      .addCase(addVacancy.rejected, handleRejected)
      .addCase(updateVacancy.rejected, handleRejected)
      .addCase(updateVisibility.rejected, handleRejected)
      .addCase(removeVacancy.rejected, handleRejected)
      .addCase(fetchVacancies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vacancies = action.payload.vacancies;
        state.pagesCount = action.payload.pagesCount;
      })
      .addCase(addVacancy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.vacancies.push(action.payload);
      })
      .addCase(removeVacancy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.vacancies.findIndex(
          (item) => item.id === action.payload
        );

        state.vacancies.splice(index, 1);
      })
      .addCase(updateVacancy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.vacancies.findIndex(
          (item) => item.id === action.payload.id
        );
        state.vacancies.splice(index, 1);
        state.vacancies.push(action.payload);
      })
      .addCase(updateVisibility.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.vacancies.findIndex(
          (item) => item.id === action.payload.id
        );

        state.vacancies[index].isActive = action.payload.isActive;
      });
  },
});

export const vacanciesReduser = vacanciesSlice.reducer;
