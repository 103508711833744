import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #1B242D;
    --secondary-color: #566470;
    --light-color: #F3F6F9;
    --white-color: #FFFFFF;
    --gray-color: #A4B1BE;
    --gray-light-color: #E6EDF4;
    --stroke-color: #D1D9E0;
    --accent-color: #10A8EC;
    --focus-color: #276CFF;
    --background-dark-color: radial-gradient(142.09% 325.18% at -3.72% -21.48%, #334250 5.49%, #111A22 89.93%) ;
    --background-light-color: #F7F9FB;
    --background-menu-color: rgba(255, 255, 255, 0.2);
    --error-color: #CE0000;
    --disable-color: #D7DCE1;
    --toastify-icon-color-success: #008714;
}
`;

export default GlobalStyle;
