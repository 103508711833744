import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNews,
  getOneNews,
  addNews,
  updateNews,
  updateVisibility,
  removeNews,
  addLink,
  updateLink,
  removeLink,
  addGallery,
  removeGallery,
} from "./newsOperations";

const newsInitialState = {
  news: [],
  pagesCount: null,
  isLoading: true,
  error: null,
};

const handlePending = (state) => {
  state.isLoading = true;
};
const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const newsSlice = createSlice({
  name: "news",
  initialState: newsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, handlePending)
      .addCase(getOneNews.pending, handlePending)
      .addCase(addNews.pending, handlePending)
      .addCase(removeNews.pending, handlePending)
      .addCase(updateNews.pending, handlePending)
      .addCase(updateVisibility.pending, handlePending)
      .addCase(addLink.pending, handlePending)
      .addCase(updateLink.pending, handlePending)
      .addCase(removeLink.pending, handlePending)
      .addCase(addGallery.pending, handlePending)
      .addCase(removeGallery.pending, handlePending)
      .addCase(fetchNews.rejected, handleRejected)
      .addCase(getOneNews.rejected, handleRejected)
      .addCase(addNews.rejected, handleRejected)
      .addCase(removeNews.rejected, handleRejected)
      .addCase(updateNews.rejected, handleRejected)
      .addCase(updateVisibility.rejected, handleRejected)
      .addCase(addLink.rejected, handleRejected)
      .addCase(updateLink.rejected, handleRejected)
      .addCase(removeLink.rejected, handleRejected)
      .addCase(addGallery.rejected, handleRejected)
      .addCase(removeGallery.rejected, handleRejected)
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.news = action.payload.news;
        state.pagesCount = action.payload.pagesCount;
      })
      .addCase(getOneNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );

        if (index === -1) {
          state.news.push(action.payload);
        } else {
          state.news[index] = action.payload;
        }
      })
      .addCase(addNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        state.news.push(action.payload);
      })
      .addCase(updateNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );
        state.news.splice(index, 1);
        state.news.push(action.payload);
      })
      .addCase(updateVisibility.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );

        state.news[index].isActive = action.payload.isActive;
      })
      .addCase(removeNews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );
        state.news.splice(index, 1);
      })
      .addCase(addLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.newsId
        );

        state.news[index].links.push(action.payload);
      })
      .addCase(updateLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.newsId
        );
        const linkIndex = state.news[index].links.findIndex(
          (item) => item.id === action.payload.id
        );
        state.news[index].links.splice(linkIndex, 1);
        state.news[index].links.push(action.payload);
      })
      .addCase(removeLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.newsId
        );
        const linkIndex = state.news[index].links.findIndex(
          (item) => item.id === action.payload.id
        );
        state.news[index].links.splice(linkIndex, 1);
      })
      .addCase(addGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );

        state.news[index].galleryLinks = action.payload.galleryLinks;
        state.news[index].galleryAlts = action.payload.galleryAlts;
      })
      .addCase(removeGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const index = state.news.findIndex(
          (item) => item.id === action.payload.id
        );

        state.news[index].galleryLinks = null;
        state.news[index].galleryAlts = null;
      });
  },
});

export const newsReduser = newsSlice.reducer;
