import axios from "../../services/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import succesHandler from "../../helpers/succesHandler";
import errorHandler from "../../helpers/errorHandler";

export const fetchVacancies = createAsyncThunk(
  "vacancies/fetchAll",
  async ({ isActive = 1 }) => {
    const response = await axios.get(
      `/vacancies/?limit=100&page=0&isActive=${isActive}`
    );
    return response.data;
  }
);

export const addVacancy = createAsyncThunk(
  "vacancies/addVacancy",
  async (vacancy, { rejectWithValue }) => {
    try {
      const response = await axios.post("/vacancies", {
        ...vacancy,
        isActive: Number(vacancy.isActive),
      });
      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const updateVacancy = createAsyncThunk(
  "vacancies/updateVacancy",
  async (vacancy, { rejectWithValue }) => {
    try {
      const response = await axios.put("/vacancies/update", vacancy);

      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const updateVisibility = createAsyncThunk(
  "vacancies/updateVisibility",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/vacancies/updateVisibility`, obj);

      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const removeVacancy = createAsyncThunk(
  "vacancies/removeContact",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/vacancies/${id}`);

      succesHandler();
      return id;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);
