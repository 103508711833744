import { createSlice } from "@reduxjs/toolkit";

const headerTypeState = "white";

const headerTypeSlice = createSlice({
  name: "headerType",
  initialState: headerTypeState,
  reducers: {
    blackType(state) {
      return (state = "black");
    },
    whiteType(state) {
      return (state = "white");
    },
  },
});

export const { blackType, whiteType } = headerTypeSlice.actions;
export const headerTypeReducer = headerTypeSlice.reducer;
