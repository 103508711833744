import axios from "../../services/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import succesHandler from "../../helpers/succesHandler";
import errorHandler from "../../helpers/errorHandler";
import transliterate from "../../helpers/transliterate";
import escapeSingleQuotes from "../../helpers/escapeSingleQuotes";

export const fetchNews = createAsyncThunk("news/fetchAll", async (settings) => {
  const { page, category, isActive = 1 } = settings;
  const response = await axios.get(
    `/news/?limit=12&page=${page}&category=${category}&isActive=${isActive}`
  );

  return response.data;
});

export const getOneNews = createAsyncThunk("news/getOneNews", async (id) => {
  const response = await axios.get(`/news/${id}`);
  return response.data;
});

export const addNews = createAsyncThunk(
  "news/addNews",
  async (news, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("headerUa", escapeSingleQuotes(news.headerUa));
      formData.append("headerEn", escapeSingleQuotes(news.headerEn));
      formData.append("tags", JSON.stringify(news.tags));
      formData.append("date", news.date);
      const transliteratedFileName = transliterate(news.img.name);
      const newFile = new File([news.img], transliteratedFileName, {
        type: news.img.type,
      });
      formData.append("img", newFile);
      formData.append("altUa", escapeSingleQuotes(news.altUa));
      formData.append("altEn", escapeSingleQuotes(news.altEn));
      formData.append("textUa", escapeSingleQuotes(news.textUa));
      formData.append("textEn", escapeSingleQuotes(news.textEn));
      formData.append("isActive", Number(news.isActive));

      const response = await axios.post("/news", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const updateNews = createAsyncThunk(
  "news/updateNews",
  async (news, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("id", news.id);
      formData.append("headerUa", escapeSingleQuotes(news.headerUa));
      formData.append("headerEn", escapeSingleQuotes(news.headerEn));
      formData.append("tags", JSON.stringify(news.tags));
      formData.append("date", news.date);
      const transliteratedFileName = transliterate(news.img.name);
      const newFile = new File([news.img], transliteratedFileName, {
        type: news.img.type,
      });
      formData.append("img", newFile);
      formData.append("imgLink", news.imgLink);
      formData.append("altUa", escapeSingleQuotes(news.altUa));
      formData.append("altEn", escapeSingleQuotes(news.altEn));
      formData.append("textUa", escapeSingleQuotes(news.textUa));
      formData.append("textEn", escapeSingleQuotes(news.textEn));

      const response = await axios.put("/news/update", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const updateVisibility = createAsyncThunk(
  "news/updateVisibility",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/news/updateVisibility`, obj);

      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const removeNews = createAsyncThunk(
  "news/removeNews",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/news/${id}`);

      succesHandler();
      return id;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const addLink = createAsyncThunk(
  "news/addLink",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await axios.post("/news/addLink", obj);

      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const updateLink = createAsyncThunk(
  "news/updateLink",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await axios.put("/news/updateLink", obj);

      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const removeLink = createAsyncThunk(
  "news/removeLink",
  async (obj, { rejectWithValue }) => {
    try {
      await axios.delete(`/news/deleteLink/${obj.id}`);

      succesHandler();
      return obj;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const addGallery = createAsyncThunk(
  "news/addGallery",
  async (obj, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append("id", obj.id);
      formData.append(
        "galleryAlts",
        escapeSingleQuotes(JSON.stringify(obj.galleryAlts))
      );
      obj.imgs.forEach((file) => {
        const transliteratedFileName = transliterate(file.name);

        const newFile = new File([file], transliteratedFileName, {
          type: file.type,
        });

        formData.append("imgs", newFile);
      });

      const response = await axios.patch("/news/addGallery", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      succesHandler();
      return response.data;
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);

export const removeGallery = createAsyncThunk(
  "news/removeGallery",
  async (id, { rejectWithValue }) => {
    try {
      await axios.patch(`/news/deleteGallery/${id}`);

      succesHandler();
      return { id };
    } catch (error) {
      errorHandler(error.response);
      return rejectWithValue(error);
    }
  }
);
