import { ReactComponent as CloseToastIcon } from "../../../imgForComponents/close.svg";
import { StyledBtn } from "./customToastCloseButton.styled";

const CustomCloseButton = ({ closeToast }) => {
  return (
    <StyledBtn onClick={closeToast} aria-label="Close Toast">
      <CloseToastIcon />
    </StyledBtn>
  );
};

export default CustomCloseButton;
