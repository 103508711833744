import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

const languageInitialState = { value: "ua" };

const languageSlice = createSlice({
  name: "language",
  initialState: languageInitialState,
  reducers: {
    toggleLanguage(state, action) {
      state.value = action.payload;
    },
  },
});

const languagePersistConfig = {
  key: "language",
  storage,
  whitelist: ["value"],
};

export const { toggleLanguage } = languageSlice.actions;
export const languageReducer = persistReducer(
  languagePersistConfig,
  languageSlice.reducer
);
