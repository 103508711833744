import { toast } from "react-toastify";
import { ReactComponent as ToastErrorImg } from "../imgForComponents/toastErrorImg.svg";

const errorHandler = (errorRes) => {
  console.log(errorRes.status);
  if (errorRes.status === 401) {
    toast.error(
      "Сесія завершилась, щоб продовжити роботу вас буде перенаправлено на сторінку логіну, щоб ви перезайшли в систему",
      { icon: <ToastErrorImg /> }
    );

    setTimeout(() => {
      window.location.href = "/login";
    }, 7000);
  } else {
    toast.error("Виникла помилка. Повторіть ваші дії знову!", {
      icon: <ToastErrorImg />,
    });
  }
};

export default errorHandler;
