import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { refresh } from "../redux/auth/authOperations";
import { selectLanguage } from "../redux/language/languageSelectors";
import getTranslatedPath from "../helpers/getTranslatedPath";
import { RestrictedRoute } from "./RestrictedRoute";
import { PrivateRouete } from "./PrivateRoute";
import CustomCloseButton from "./commonComponents/customToastCloseButton/customToastCloseButton";
const SharedLayout = lazy(() =>
  import("./commonComponents/sharedLayout/SharedLayout")
);
const AdminSharedLayout = lazy(() =>
  import("./adminComponents/adminSharedLayout/adminSharedLayout")
);
const Main = lazy(() => import("../pages/main/main"));
const AboutUs = lazy(() => import("../pages/aboutUs/aboutUs"));
const Projects = lazy(() => import("../pages/projects/projects"));
const ProjectPage = lazy(() => import("../pages/projectPage/projectPage"));
const Vacansies = lazy(() => import("../pages/vacansies/vacansies"));
const VacancyPage = lazy(() => import("../pages/vacancyPage/vacancyPage"));
const News = lazy(() => import("../pages/news/news"));
const NewsPage = lazy(() => import("../pages/newsPage/newsPage"));
const MainAnalytics = lazy(() =>
  import("../pages/mainAnalytics/mainAnalytics")
);
const Analytics = lazy(() => import("../pages/analytics/analytics"));
const Services = lazy(() => import("../pages/Services/Services"));
const Politics = lazy(() => import("../pages/politics/politics"));
const Directory = lazy(() => import("../pages/directory/directory"));
const CompanyPolitics = lazy(() =>
  import("../pages/companyPolitics/companyPolitics")
);
const Reporting = lazy(() => import("../pages/reporting/reporting"));
const AnticorruptionActivities = lazy(() =>
  import("../pages/anticorruptionActivities/anticorruptionActivities")
);
const AdminNews = lazy(() => import("../pages/adminPages/adminNews/adminNews"));
const UpdateNewsPage = lazy(() =>
  import("../pages/adminPages/updateNewsPage/UpdateNewsPage")
);
const AdminVacancies = lazy(() =>
  import("../pages/adminPages/adminVacansies/adminVacansies")
);
const UpdateVacancyPage = lazy(() =>
  import("../pages/adminPages/updateVacansiesPage/updateVacansiesPage")
);
// const UnderDevelopment = lazy(() =>
//   import("../pages/underDevelopment/underDevelopment")
// );
const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const Login = lazy(() => import("../pages/adminPages/login/login"));

const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    width: 320px;
    min-height: 74px;
    margin-bottom: 20px;
    padding: 24px 16px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: left;
    color: #1b242d;
  }

  .Toastify__toast-body {
    width: 224px;
    padding: 0;
  }

  .Toastify__close-button--light {
    margin: auto;
    opacity: 1;
  }

  .Toastify__progress-bar--bg {
    background-color: transparent;
  }

  .Toastify__progress-bar {
    background-color: transparent;
  }

  .Toastify__close-button > svg {
    height: 24px;
    width: 24px;
  }

  .Toastify__toast-icon {
    width: 24px;
    margin-inline-end: 16px;
  }

  .Toastify__toast--success {
    background: #cce7d0;
    border: 1px solid #008714;
  }

  .Toastify__toast--error {
    line-height: 19.2px;
    background-color: #f5cccc;
    border: 1px solid #ce0000;
  }
`;

function App() {
  const lang = useSelector(selectLanguage);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(refresh());
    const langFromLocation =
      location.pathname.split("/")[1] === "en" ? "en" : "ua";

    if (langFromLocation === "ua" || langFromLocation === "en") {
      if (langFromLocation !== lang) {
        const translatedPath = getTranslatedPath(location.pathname, lang);
        navigate(translatedPath, { replace: true });
      }
    }
  }, [dispatch, lang, i18n, location.pathname, navigate]);

  return (
    <>
      <CustomToastContainer
        position="top-right"
        closeButton={<CustomCloseButton />}
      />
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<Main />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<ProjectPage />} />
          <Route path="career" element={<Vacansies />} />
          <Route path="career/:id" element={<VacancyPage />} />
          <Route path="news" element={<News />} />
          <Route path="news/:id" element={<NewsPage />} />
          <Route path="analytics">
            <Route index element={<MainAnalytics />} />
            <Route
              path="dashbord-humanitarnoyi-dopomohy"
              element={<Analytics />}
            />
            <Route
              path="nadavachi-sotsialnykh-posluh"
              element={<Analytics />}
            />
            <Route path="dashboard-vpo" element={<Analytics />} />
            <Route path="dashboard-edopomoga" element={<Analytics />} />
            <Route path="haryachi-liniyi" element={<Analytics />} />
            <Route
              path="nadavachiv-sotsialnykh-posluh"
              element={<Analytics />}
            />
            <Route path="protezni-pidpryyemstva" element={<Analytics />} />
            <Route
              path="tsyfrova-hotovnist-sotsposlug"
              element={<Analytics />}
            />
            <Route path="tsyfrova-hotovnist-upszn" element={<Analytics />} />
          </Route>

          <Route path="eservices" element={<Services />} />
          <Route path="directories" element={<Directory />} />
          <Route path="companypolicies">
            <Route index element={<CompanyPolitics />} />
            <Route path="privacypolicy" element={<Politics />} />
            <Route path="reporting" element={<Reporting />} />
            <Route
              path="anticorruptionActivities"
              element={<AnticorruptionActivities />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/en" element={<SharedLayout />}>
          <Route index element={<Main />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<ProjectPage />} />
          <Route path="career" element={<Vacansies />} />
          <Route path="career/:id" element={<VacancyPage />} />
          <Route path="news" element={<News />} />
          <Route path="news/:id" element={<NewsPage />} />
          <Route path="analytics">
            <Route index element={<MainAnalytics />} />
            <Route
              path="dashbord-humanitarnoyi-dopomohy"
              element={<Analytics />}
            />
            <Route
              path="nadavachi-sotsialnykh-posluh"
              element={<Analytics />}
            />
            <Route path="dashboard-vpo" element={<Analytics />} />
            <Route path="dashboard-edopomoga" element={<Analytics />} />
            <Route path="haryachi-liniyi" element={<Analytics />} />
            <Route
              path="nadavachiv-sotsialnykh-posluh"
              element={<Analytics />}
            />
            <Route path="protezni-pidpryyemstva" element={<Analytics />} />
            <Route
              path="tsyfrova-hotovnist-sotsposlug"
              element={<Analytics />}
            />
            <Route path="tsyfrova-hotovnist-upszn" element={<Analytics />} />
          </Route>

          <Route path="eservices" element={<Services />} />
          <Route path="directories" element={<Directory />} />
          <Route path="companypolicies">
            <Route index element={<CompanyPolitics />} />
            <Route path="privacypolicy" element={<Politics />} />
            <Route path="reporting" element={<Reporting />} />
            <Route
              path="anticorruptionActivities"
              element={<AnticorruptionActivities />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route
          path="/login"
          element={
            <RestrictedRoute component={Login} redirectTo="/adminpage/news" />
          }
        />

        <Route
          path="/adminpage"
          element={
            <PrivateRouete component={AdminSharedLayout} redirectTo="/login" />
          }
        >
          <Route
            path="news"
            element={
              <PrivateRouete component={AdminNews} redirectTo="/login" />
            }
          />
          <Route
            path="news/:id"
            element={
              <PrivateRouete component={UpdateNewsPage} redirectTo="/login" />
            }
          />
          <Route
            path="vacancies"
            element={
              <PrivateRouete component={AdminVacancies} redirectTo="/login" />
            }
          />
          <Route
            path="vacancies/:id"
            element={
              <PrivateRouete
                component={UpdateVacancyPage}
                redirectTo="/login"
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}

const WrappedApp = () => {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
};

export default WrappedApp;
