import { createSlice } from "@reduxjs/toolkit";

const analyticsInitialState = [
  {
    id: "dashbord-humanitarnoyi-dopomohy",
    headerUA: "Гуманітарна допомога",
    headerEN: "Automated System of Humanitarian Aid Registration",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODRlOWNjYjctMjdhYi00YzllLWEwODEtMWVkMjUxZTc0N2NiIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "2500px",
    imgName: "humanitarianAid",
  },
  {
    id: "nadavachi-sotsialnykh-posluh",
    headerUA: "Надавачі соціальних послуг",
    headerEN: "Social service providers",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNzM3ZmE1NDItYmMzMS00ZjIyLWI3YzUtYzliYTFhNTkzNjFmIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "2000px",
    imgName: "socialServiceProviders",
  },
  {
    id: "dashboard-vpo",
    headerUA: "Внутрішньо переміщені особи",
    headerEN: "Internally displaced persons",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNDk1ZGY1YjktNTY3NC00ZjE2LThmMWEtMTE4NjRkYzEzNjY0IiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "1607px",
    imgName: "internallyDisplacedPersons",
  },
  {
    id: "dashboard-edopomoga",
    headerUA: "єДопомога",
    headerEN: "eDopomoga",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNjRlNWU2NDYtNTI1Zi00MDdhLWI0MTItOTUwNmJiNjY4NWExIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "2632px",
    imgName: "eDopomoga",
  },
  {
    id: "haryachi-liniyi",
    headerUA: "«Гарячі лінії» із попередження домашнього насильства",
    headerEN: "Hotlines for the prevention of domestic violence",
    link: "https://app.powerbi.com/view?r=eyJrIjoiMzE4ZmU1MmEtNGMyYy00NDgyLTkyNjAtMDE5N2Y3ODY4NTgyIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "950px",
    imgName: "hotlinesForPreventionDomesticViolence",
  },
  {
    id: "nadavachiv-sotsialnykh-posluh",
    headerUA: "Реєстри надавачів соціальних послуг",
    headerEN: "Registers of social service providers",
    link: "https://app.powerbi.com/view?r=eyJrIjoiYWIzYmQzMTgtNmIyYy00YTZiLTg1MzItMzQ4MmJkMTBjMTJiIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "1255px",
    imgName: "registersSocialServiceProviders",
  },
  {
    id: "protezni-pidpryyemstva",
    headerUA: "Протезні підприємства",
    headerEN: "Prosthetic enterprises",
    link: "https://app.powerbi.com/view?r=eyJrIjoiNmQ0NThjYjYtZDcxYi00MDgxLTg1MjQtZmRlM2Y4YjliYmJjIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "860px",
    imgName: "prostheticEnterprises",
  },
  {
    id: "tsyfrova-hotovnist-sotsposlug",
    headerUA: "Рівень цифрової готовності надавачів соцпослуг",
    headerEN: "Digital readiness level of social service providers",
    link: "https://app.powerbi.com/view?r=eyJrIjoiY2RjZGU5YzItNGZmNi00Zjc2LWJjYzItMjcxNmZkOWM3NmQzIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "860px",
    imgName: "digitalReadinessLevelSocialServiceProviders",
  },
  {
    id: "tsyfrova-hotovnist-upszn",
    headerUA: "Рівень цифрової готовності УПСЗН",
    headerEN: "Digital readiness level of social protection departments",
    link: "https://app.powerbi.com/view?r=eyJrIjoiODAzNzVhNmItYjAxNC00NGE5LWIyNDktNDY0OWI3OTliN2ZhIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9",
    height: "860px",
    imgName: "digitalReadinessLevelSocialProtectionDepartments",
  },
];
const analyticsSlice = createSlice({
  name: "analytics",
  initialState: analyticsInitialState,
  reducers: {},
});

export const analyticsReducer = analyticsSlice.reducer;
